<section>
    <div class="dashboard__maskinist" *ngIf="!this.auth.getUserInfo()?.adminuser">
        <h2 class="dashboard__heading" mdcHeadline2 *ngIf="maskinist">Hei, {{ maskinist.fornavn }}</h2>

        <div class="dashboard-group">
            <div class="dashboard__subgroup">
                <mdc-card class="card--saleslink">
                    <div class="card__header">
                        <h3 mdcHeadline4>Dagens forestillinger</h3>
                    </div>
                    <div class="card__content">
                        <ng-container *ngIf="mineforestillinger$ | async as maskinistdata; else forestillingerLaster">
                            <ng-container *ngFor="let ring of maskinistdata.ringer">
                                <div *ngIf="ring.forestillinger && ring.forestillinger.length">
                                    <h4 mdcBody1 class="text__medium-emphasis">{{ ring.forestillinger[0].spillested.navn }},
                                        {{ ring.forestillinger[0].spillested.stedsnavn }}</h4>
                                    <mdc-list interactive="false">
                                        <mdc-list-item *ngFor="let forestilling of ring.forestillinger">
                                            <div>{{ forestilling.tidspunkt }} {{ forestilling.film.norsktittel }}</div>
                                        </mdc-list-item>
                                    </mdc-list>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-template #forestillingerLaster>
                            <app-spinner [inline]="true"></app-spinner>
                        </ng-template>
                        <button [routerLink]="['/salg', 'kasse']" mdc-button raised>Gå til kasse</button>
                    </div>
                </mdc-card>
                <mdc-card>
                    <a href="{{hentBrukerveiledninglenke()}}" target="_blank">Bruksanvisning Kinologg – versjon desember 2021</a>
                </mdc-card>
            </div>

            <mdc-card>
                <div class="card__header">
                    <h3 mdcHeadline4>Neste 7 jobber</h3>
                    <a routerLink="/kjoreplan">Se alle</a>
                </div>
                <div class="card__content">
                    <mdc-list interactive="false" *ngIf="kjoreplan?.length">
                        <mdc-list-item *ngFor="let item of kjoreplan">
                            <div class="list-item-text">{{ item.spillested.stedsnavn }}</div>
                            <div class="list-item-text">{{ item.dato | date:'d.M.yyyy' }}</div>
                        </mdc-list-item>
                    </mdc-list>
                    <p class="p__body" *ngIf="!kjoreplan?.length && !henterKjoreplan">Du har ingen kommende jobber.</p>
                    <app-spinner [inline]="true" *ngIf="!kjoreplan && henterKjoreplan"></app-spinner>
                </div>
            </mdc-card>

            <mdc-card>
                <div class="card__header">
                    <h3 mdcHeadline4>Kontaktinformasjon</h3>
                </div>
                <div class="card__content kontakt-container">
                    <div>
                        <h4 mdcHeadline5>Administrasjonen</h4>
                        <div class="kontakt">
                            <h5 mdcHeadline6>André Jakumeit</h5>
                            <p>Daglig leder</p>
                            <p>Mobil: <a href="tel:41178368">+47 41 17 83 68</a></p>
                            <p>E-post: <a href="mailto:andre@bygdekinoen.no">andre@bygdekinoen.no</a></p>
                        </div>
                        <div class="kontakt">
                            <h5 mdcHeadline6>Anne-Marie Otter</h5>
                            <p>Filmrådgiver</p>
                            <p>Telefon: <a href="tel:22474621">+47 22 47 46 21</a></p>
                            <p>Mobil: <a href="tel:95762624">+47 957 62 624</a></p>
                            <p>E-post: <a href="mailto:anne-marie@bygdekinoen.no">anne-marie@bygdekinoen.no</a></p>
                        </div>
                        <div class="kontakt">
                            <h5 mdcHeadline6>Grethe Næss</h5>
                            <p>Kinorådgiver</p>
                            <p>Telefon: <a href="tel:22474626">+47 22 47 46 26</a></p>
                            <p>Mobil: <a href="tel:93636498">+47 936 36 498</a></p>
                            <p>E-post: <a href="mailto:grethe@bygdekinoen.no">grethe@bygdekinoen.no</a></p>
                        </div>
                        <div class="kontakt">
                            <h5 mdcHeadline6>Anita Sanden</h5>
                            <p>Regnskapskonsulent</p>
                            <p>Telefon: <a href="tel:22474613">+47 22 47 46 13</a></p>
                            <p>Mobil: <a href="tel:90630637">906 30 637</a></p>
                            <p>E-post: <a href="mailto:anita@kino.no">anita@kino.no</a></p>
                        </div>
                    </div>
                    <div>
                        <h4 mdcHeadline5>Teknisk</h4>
                        <div class="kontakt">
                            <h5 mdcHeadline6>Roar Bakke</h5>
                            <p>Teknisk koordinator</p>
                            <p>Mobil: <a href="tel:91545602">915 45 602</a></p>
                            <p>E-post: <a href="mailto:roarB@kino.no">roarB@kino.no</a></p>
                        </div>
                        <div class="kontakt">
                            <h5 mdcHeadline6>Unique Digital Nordic AS</h5>
                            <p>Ang. MT-bokser</p>
                            <p>Telefon: <a href="tel:80035250">800 35 250</a></p>
                            <p>Epost: <a href="mailto:support@movietransit.com">support@movietransit.com</a></p>
                        </div>
                        <div class="kontakt">
                            <h5 mdcHeadline6>Øvrig support</h5>
                            <p>Kinotech: <a href="tel:75560000">75 56 00 00</a></p>
                            <p>Verifone: <a href="tel:23247400">23 24 74 00</a></p>
                        </div>
                    </div>
                </div>
            </mdc-card>
            <mdc-card class="card--maskinister">
                <div class="card__header">
                    <h3 mdcHeadline4>Maskinister</h3>
                </div>
                <div class="card__content" *ngIf="maskinister">
                    <mdc-data-table>
                        <table mdcDataTableTable>
                            <thead>
                                <tr mdcDataTableHeaderRow>
                                    <td mdcDataTableHeaderCell>Navn</td>
                                    <td mdcDataTableHeaderCell numeric>Telefon</td>
                                    <td mdcDataTableHeaderCell>Epost</td>
                                    <td mdcDataTableHeaderCell>Adresse</td>
                                </tr>
                            </thead>
                            <tbody MDCDataTableContent>
                                <tr mdcDataTableRow *ngFor="let m of maskinister">
                                    <td mdcDataTableCell>{{ m.navn }}</td>
                                    <td mdcDataTableCell numeric>{{ m.telefon1 }}</td>
                                    <td mdcDataTableCell>{{ m.epost }}</td>
                                    <td mdcDataTableCell>{{ m.postadr }}, {{ m.postnr }} {{ m.poststed }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </mdc-data-table>
                </div>
            </mdc-card>
        </div>
    </div>
</section>